import { CACTUS_100, MOSS_100, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

const customMobileBreakpoint = 870;

export const Section = styled.section`
  background-color: ${MOSS_100};
`;

export const Content = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
  display: grid;
  row-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
`;

export const SectionTitle = styled.h2`
  font-size: 28px;
  line-height: 36px;
  margin: 0;
  text-align: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    font-size: 48px;
    line-height: 56px;
    text-align: left;
  }
`;

export const SuiteWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

export const SuiteImage = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;
  grid-column: span 12;
  .gatsby-image-wrapper {
    grid-column: 3 / span 8;
    img {
      height: auto;
    }
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    grid-template-columns: repeat(6, 1fr);
    gap: 24px;
    grid-column: span 6;
    .gatsby-image-wrapper {
      grid-column: 2 / span 4;
    }
  }
`;

export const LeftSuiteImage = styled(SuiteImage)`
  order: -1;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    order: unset;
  }
`;

export const SuiteDescription = styled.div`
  grid-column: span 12;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 0, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    grid-column: span 6;
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 3)};
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 0;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
      margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
      font-size: 36px;
      line-height: 48px;
      text-align: left;
    }
  }
  h4 {
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
      font-size: 24px;
      line-height: 32px;
    }
  }
  p {
    margin: 0;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
    letter-spacing: 0.01rem;
  }
  ul {
    list-style-type: none;
    padding: 0;
    letter-spacing: 0.01rem;
  }

  ul li {
    position: relative;
    padding-left: 20px;
    margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
    ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
      margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
    }
  }

  ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    background-color: ${CACTUS_100};
    border-radius: 50%;
  }
`;

export const LeftImageSuiteDescription = styled(SuiteDescription)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    grid-column: 2 / span 5;
  }
`;
