import { OwnUpGridWrapper, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import React from 'react';

import {
  BenefitDescription,
  BenefitItem,
  BenefitNumber,
  BenefitsWrapper,
  Content,
  Section
} from './elements';

export const BenefitsInNumbers = () => {
  return (
    <OwnUpThemeProvider>
      <Section aria-label="benefits-in-numbers">
        <OwnUpGridWrapper>
          <Content>
            <BenefitsWrapper>
              <BenefitItem>
                <BenefitNumber>50,000+</BenefitNumber>
                <BenefitDescription>High-intent mortgage shoppers every month</BenefitDescription>
              </BenefitItem>
              <BenefitItem>
                <BenefitNumber>50% of Top 10</BenefitNumber>
                <BenefitDescription>Mortgage lenders work with Own Up</BenefitDescription>
              </BenefitItem>
            </BenefitsWrapper>
          </Content>
        </OwnUpGridWrapper>
      </Section>
    </OwnUpThemeProvider>
  );
};
