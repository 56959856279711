import { OwnUpGridWrapper, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import React from 'react';
import { FaqSection } from '../../faq';

import { NewBenefitsSection } from './elements';
import faqData from './mock-data.json';

export const FAQs = () => {
  return (
    <OwnUpThemeProvider>
      <NewBenefitsSection aria-label="FAQa">
        <OwnUpGridWrapper>
          <FaqSection faqData={faqData} title={'Lender FAQs'} />
        </OwnUpGridWrapper>
      </NewBenefitsSection>
    </OwnUpThemeProvider>
  );
};
