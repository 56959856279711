import { ALOE_100, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

const customMobileBreakpoint = 870;

export const Section = styled.section`
  background-color: white;
  padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;
  }
`;

export const Content = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 2.5)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  }
  background-color: ${ALOE_100};
  border-radius: 8px;
`;

export const BenefitsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 8)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    flex-direction: row;
  }
`;

export const BenefitItem = styled.div`
  max-width: 400px;
  color: white;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    text-align: center;
  }
`;

export const BenefitNumber = styled.span`
  font-size: 40px;
  line-height: 48px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const BenefitDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  max-width: 350px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    font-size: 20px;
    line-height: 28px;
    margin: 0 auto;
  }
`;
