import {
  OwnUpFillButtonPrimaryAccent,
  OwnUpUnderlineLink,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

const customBreakpoint = 1228;
const customMobileBreakpoint = 870;

export const Section = styled.section`
  background-color: white;
  color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.text.primary};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10)}px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 0, 0)};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0, 0)};
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
  }
  gap: 0 32px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  width: 100%;
`;

export const Button = styled(OwnUpFillButtonPrimaryAccent)`
  width: 100%;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('sm')} {
    width: 312px;
  }
`;

export const ImageWrapper = styled.div`
  grid-column: 1;
  grid-row: 2;
  justify-self: center;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    grid-row: 1 / span 2;
  }
`;
export const CTAArea = styled.div`
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  margin-left: 0;
  width: 100%;
  padding-top: 0;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customMobileBreakpoint)} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
    grid-column: 2 / span 2;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(9)}px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    max-width: 612px;
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xl')} {
    margin-left: -50px;
  }
`;
export const CTAHeadline = styled.h1`
  font-size: 28px;
  line-height: 36px;
  margin: 0;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up(customBreakpoint)} {
    font-size: 48px;
    line-height: 56px;
  }
`;
export const CTASubHeadline = styled.h2`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 400;
  margin: 0;
`;

export const LendersEmail = styled(OwnUpUnderlineLink)`
  margin-left: 8px;
`;
